@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.wrapper {
  padding: 5px 10px;
  background-color: variable.$whiteColor;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  .flex {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    &.isHomePage {
      .numberOne {
        margin-top: -35px;
      }
    }
    .numberOne {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 3.9vw;
      font-family: variable.$primaryFontBold;
      text-align: center;
      width: 100%;
      margin-bottom: 5px;
      color: variable.$blackColor;
      background-color: variable.$whiteColor;
      margin-top: 0;
      margin-bottom: 0;
      padding: 15px 35px 5px;
      max-width: max-content;
      position: relative;
      z-index: 1;

      img {
        width: 2.9vw;
      }
    }
    > div {
      flex: 1;
      max-width: 360px;
      &.seenOn {
        width: 100%;
        flex: none;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 15px;
        font-size: variable.$size14;
        font-family: variable.$primaryFontSemiBold;
        padding-bottom: 10px;
        padding-top: 16px;
        color: variable.$blackColor;
        .tfi {
          max-width: 132px;
          width: 132px;
          height: 35px;
          background-position: left top;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .m6 {
          max-width: 48px;
          width: 48px;
          height: 27px;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        img {
          flex: 1;
        }
      }
    }
  }
}
.googleStars {
  display: flex;
  gap: 10px;
  align-items: center;
  > div:first-child {
    flex: 1;
  }
  img {
    height: auto;
  }
}
.trustpilotwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 320px;
  margin: 6px auto 30px;
  .ratingStars {
    position: relative;
  }
}
.text {
  font-size: variable.$size14;
  color: #000;
  font-family: variable.$primaryFontSemiBold;
  white-space: nowrap;
  &.large {
    font-size: variable.$size18;
  }
}
.hasProductOfTheYearLogo {
  display: flex;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  position: relative;
  align-items: center;
  .flex {
    .numberOne {
      padding: 5px 20px 5px;
    }
  }
  .trustpilotwrap {
    flex-wrap: wrap;
    margin-bottom: 0;
    .ratingStars {
      flex: 1;
    }
    .text {
      flex: 1;
    }
    .tpLogo {
      margin-top: 10px;
      width: 100%;
      img {
        margin: auto;
      }
    }
  }
  .wrapper {
    box-shadow: none;
  }
}
.avisCount {
  max-width: 350px;
  margin: auto;
  font-size: variable.$size12;
  color: #000;
  text-align: center;
  margin-top: 1px;
  font-family: variable.$primaryFont;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  text-align: center;
}
@include mixin.mediaSmallTabletSize() {
  .section {
    order: initial;
  }
  .wrapper {
    padding: 15px 10px;
    .flex {
      gap: 10px;
      .numberOne {
        font-size: variable.$size20;
        margin-top: 0px;
        img {
          width: 25px;
        }
      }
      &.isHomePage {
        .numberOne {
          margin-top: -60px;
        }
      }
      > div {
        max-width: none;
      }
    }
  }
  .trustpilotwrap {
    max-width: 460px;
  }
  .text {
    font-size: variable.$size20;
  }
  .avisCount {
    text-align: center;
    margin: 6px auto;
    font-size: variable.$size14;
  }
  .hasProductOfTheYearLogo {
    .flex {
      .numberOne {
        padding: 25px 35px 5px;
      }
    }
    .trustpilotwrap {
      flex-wrap: nowrap;
      margin-bottom: 30px;
      .text,
      .ratingStars {
        flex: none;
      }

      .tpLogo {
        margin-top: 0;
        width: auto;
        img {
          margin: auto;
        }
      }
    }
    .wrapper {
      box-shadow: none;
    }
  }
}
@include mixin.mediaDesktopSize() {
  .wrapper {
    padding: 15px 15px 5px;
    background-color: variable.$whiteColor;
    .flex {
      flex-direction: row;
      justify-content: space-evenly;
      gap: 10px;
      background-color: #fff;
      position: relative;
      z-index: 1;
      padding: 0 15px;
      padding-top: 25px;
      flex-wrap: wrap;

      &.isHomePage {
        margin-top: -80px;
        .numberOne {
          margin: 0;
        }
      }
      > div {
        flex: none;
        width: auto;
        &:last-child {
          flex: 1;
        }
        &.seenOn {
          gap: 30px;
          font-size: variable.$size20;
          white-space: nowrap;
          align-items: center;
          padding-top: 0;
          .tfi {
            max-width: 92px;
            width: 92px;
            height: 35px;
            background-size: 245%;
            background-position: left center;
          }
          .m6 {
            max-width: 48px;
            width: 48px;
            height: 35px;
          }
        }
      }
      .numberOne {
        width: auto;
        padding-bottom: 25px;
        padding-top: 0;
        margin: 0;
      }
    }
  }
  .trustpilotwrap {
    gap: 20px;
    max-width: 520px;
    > div {
      max-width: 155px;
      &:last-child {
        max-width: 130px;
        flex: 1 1;
        margin: auto;
        img {
          width: 100%;
        }
      }
    }
  }
  .text {
    font-family: variable.$primaryFontSemiBold;
  }
}
@include mixin.mediaDesktop() {
  .wrapper {
    .flex {
      flex-wrap: nowrap;
      > div {
        &.seenOn {
          flex: none;
          padding-bottom: 23px;
          white-space: nowrap;
          gap: 10px;
          width: auto;
        }
      }
      .numberOne {
        padding-inline: 15px;
      }
    }
  }
}
