@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.rating {
  display: flex;
  background-color: #ccc;
  position: relative;
  span {
    flex: 1;
    position: relative;
    padding: 3px;
    border-inline: 2px solid variable.$whiteColor;
    img {
      filter: contrast(1) invert(1);
    }
  }
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #00b578;
    border: none;
  }
}

@include mixin.mediaSmallTabletSize() {
  .rating {
    span {
      img {
        width: 30px;
      }
    }
  }
}
